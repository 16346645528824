import React, {useEffect} from 'react';
import {useIonRouter} from '@ionic/react';
import {useParams} from 'react-router-dom';
import Loader from 'ui/@components/loader';
import useFirebaseFunctions from 'ui/@hooks/use-firebase-functions';
import useLocalizedRoutes from 'ui/@hooks/use-localized-routes';
import {impossible} from 'utils/assert';

const ShortUrl: React.FC = () => {
  const router = useIonRouter();
  const {getRouteByName} = useLocalizedRoutes();
  const {hash} = useParams<{hash: string}>();

  const {functionsApiClient} = useFirebaseFunctions();

  useEffect(() => {
    (async () => {
      try {
        const response = await functionsApiClient.get(`/shortUrl/${hash}`);
        const url = response.data ?? impossible('Failed to fetch long url');

        window.location.href = url;
      } catch (e) {
        window.location.href = '/';
      }
    })();
  }, [getRouteByName, hash, router, functionsApiClient]);

  return <Loader />;
};

export default ShortUrl;
