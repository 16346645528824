class ImpossibleError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ImpossibleError';
  }
}

export const impossible = (reason?: string): never => {
  throw new ImpossibleError(reason);
};

class TodoError extends Error {
  constructor(description?: string) {
    super(description);
    this.name = 'TodoError';
  }
}

/** @deprecated this should never appear in production */
export const todo = (description?: string): never => {
  throw new TodoError(description);
};

export class RuntimeError extends Error {}

export const fail = (why: string | Error): never => {
  if (why instanceof Error) throw why;
  else throw new RuntimeError(why);
};

export class UnreachableError extends Error {}

export const unreachable = (witness: never): never => {
  // TODO handle circular objects gracefully
  throw new UnreachableError(`Unexpected case: ${JSON.stringify(witness)}`);
};

export const isSomeEnum = <T extends {}>(e: T) => (token: any): token is T[keyof T] =>
  Object.values(e).includes(token as T[keyof T]);
