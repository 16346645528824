import * as z from 'zod';
import {GetServicesQuery} from 'ui/admin/orders/single-order/@components/invoice-info/service-invoice/get-services.generated';

export type Service = GetServicesQuery['service'][0];
export type Drug = NonNullable<GetServicesQuery['service'][0]['drug']>;

export type ServiceInvoiceUpdateEvent =
  | {
      action: 'toggleLineItem';
      serviceId: string;
    }
  | {
      action: 'toggleAll';
      serviceIds: string[];
    };

const lineItemDataSchema = z.object({
  drug_name: z.string(),
  drug_strength: z.string(),
  quantity: z.number(),
  rxNumber: z.string().optional(), // optional for historical reasons
  din: z.string(),
  prescriber: z.string().optional(),
  // e.g. "Prix ajusté au prix de l’équivalent le plus bas",
  // "Personne non admissible, assurance maladie ou carte invalide"
  info: z.string().optional(),
  order_line_item_id: z.string().optional(),
  service_id: z.string(),
  insuranceCompany: z.string().optional(), // optional for historical reasons
  matchedToOrderLineItemId: z.string().optional(),

  totalPrice: z.number(),
  drugPrice: z.number().optional().nullable(), // optional for historical reasons
  rgamPrice: z.number().optional().nullable(), // optional for historical reasons
  grossPrice: z.number().optional().nullable(), // optional for historical reasons
  insuredAmount: z.number(),
  professionalFees: z.number(),
  deductible: z.number(),
  copay: z.number(),
  toPay: z.number(),
  gst: z.number().default(0), // default to 0 for historical reasons
  qst: z.number().default(0), // default to 0 for historical reasons
});

export type LineItemData = z.infer<typeof lineItemDataSchema>;

const patientDataSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.string().optional(),
  health_insurance_number: z.string().optional(),
  total: z.number(),
  gst: z.number().default(0), // default to 0 for historical reasons
  qst: z.number().default(0), // default to 0 for historical reasons
  line_items: z.array(lineItemDataSchema),
  address_line1: z.string().optional(),
  address_line2: z.string().optional(),
});

export type PatientData = z.infer<typeof patientDataSchema>;

// TODO this should be enforced in the data model of patient.preferred_language
export enum PreferredLanguage {
  French = 'fr',
  English = 'en',
}

export const isValidLanguage = (s: string): s is PreferredLanguage =>
  (Object.values(PreferredLanguage) as string[]).includes(s);

export const stringToLanguage = (s: string): PreferredLanguage =>
  isValidLanguage(s) ? s : fail(`Invalid patient preferred language ${s}`);

export const InvoiceDetailSchema = z.object({
  version: z.literal('v1'),
  language: z.nativeEnum(PreferredLanguage).optional(),
  patients: z.array(patientDataSchema),
});

export type SerializedInvoiceData = z.input<typeof InvoiceDetailSchema>;
export type InvoiceData = z.infer<typeof InvoiceDetailSchema>;
export type EditorState = {selectedServices: Set<string>};

/**
 * The following lines were copied from
 * medzy/packages/functions/src/api/hasura/incoming-change/types.ts
 * The only values we need are related to the prescriber, so everything else has been removed to keep it simple
 */

export const incomingRxSpec = z.object({
  Prescripteur: z.string(),
  PrenomPrescripteur: z.optional(z.string()),
  NomPrescripteur: z.optional(z.string()),
});

export type LogipharmRx = z.infer<typeof incomingRxSpec>;
