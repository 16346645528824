import React, {useContext, useEffect, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {signOut} from 'firebase/auth';
import {getPlatformAuth} from 'initializers/firebase';
import {useTranslation} from 'react-i18next';
import store from 'store2';
import Loader from 'ui/@components/loader';
import FirebaseAuthContext from 'ui/@contexts/firebase-auth-context';
import useLocalizedRoutes from 'ui/@hooks/use-localized-routes';
import useTitle from 'ui/@hooks/use-title';

const Logout: React.FC = () => {
  const {t} = useTranslation(['common', 'logout']);
  useTitle(`${t('title')} | ${t('logout:title')}`);

  const {getRouteByName} = useLocalizedRoutes();

  const {authState} = useContext(FirebaseAuthContext);

  const [didLogout, setDidLogout] = useState<boolean>(authState.status === 'out');

  const client = useApolloClient();

  useEffect(() => {
    if (didLogout) return;

    (async () => {
      const auth = getPlatformAuth();
      await signOut(auth);
      await client.resetStore();
      await window.zE?.('webWidget', 'logout');
      store.session.clearAll();

      setDidLogout(true);
    })();
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (didLogout) {
    window.location.href = getRouteByName('login').path;
  }

  return <Loader />;
};

export default Logout;
