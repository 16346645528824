import {Device} from '@capacitor/device';
import I18next, {InitOptions, LanguageDetectorAsyncModule} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import resources from 'locales';
import {initReactI18next} from 'react-i18next';

const i18NextConfig: InitOptions = {
  debug: process.env.NODE_ENV === 'development',
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  saveMissing: true, // Does not really save missing keys, but triggers the missingKeyHandler
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Missing translation for ${key} in ${ns} for ${lng}`);
    }
  },
  load: 'languageOnly',
  resources,
  supportedLngs: ['fr', 'en'],
  detection: {
    order: ['querystring', 'path', 'localStorage', 'cookie'],
    lookupQuerystring: 'lang',
    caches: ['localStorage', 'cookie'],
  },
};

let baseLanguageDetector: I18nextBrowserLanguageDetector;
const capacitorDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: (services, options) => {
    baseLanguageDetector = new LanguageDetector(services, options);
  },
  detect: async (callback) => {
    const baseValues = baseLanguageDetector.detect() ?? [];
    const {value} = await Device.getLanguageCode();
    const baseValuesIsAString = typeof baseValues === 'string' || baseValues instanceof String;
    const priorityMatches = [
      ...(baseValuesIsAString ? [baseValues] : baseValues),
      value.split('-')[0],
    ];
    const bestMatch = baseLanguageDetector.services.languageUtils.getBestMatchFromCodes(
      priorityMatches
    );

    if (baseLanguageDetector.services.languageUtils.isSupportedCode(bestMatch)) {
      callback(bestMatch);
    } else {
      callback(i18NextConfig.fallbackLng as string);
    }

    return undefined;
  },
  cacheUserLanguage: (lng) => {
    baseLanguageDetector.cacheUserLanguage(lng);
  },
};

const initializeI18next = () => {
  void I18next.use(initReactI18next).use(capacitorDetector).init(i18NextConfig);

  return I18next;
};

export default initializeI18next;
